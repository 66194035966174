<template>
  <div>
    <p class="text-black font-medium md:text-[20px] text-[15px]">{{ $t('Customer') }}</p>
    <div class="grid gap-y-4 gap-x-6 mt-7 md:grid-cols-2 grid-cols-1">
      <div>
        <div class="relative h-14">
          <v-select v-if="!selectionInProgress" class="w-full select-border h-full bg-transparent rounded-md border border-solid border-silver" id="phone-number" v-model="customerData.phone" @search:blur="handleChangePhone(customerData.phone)"
            autocomplete="nofill" label="label" :options="phones" search @search="handlePhoneNumberSearch" @keyup.enter.native="handlePhoneNumberSearch" name="phone number"/>
          <div v-show="selectionInProgress">
            <shipblu-input labelBackground="white" ref="phoneInput" autocomplete="nofill" id="phone-input" name="phone number" :labelPlaceholder="$t('Phone Number')" :required="true"
              v-model="searchPhoneVal" type="text" class="w-full" v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }">
              <feather-icon v-if="selectionInProgress" class="w-4 cursor-pointer mr-1 text-primary" icon="XIcon" @click="convertTextToSelect()"></feather-icon>   
              <span class="text-silver" v-if="selectionInProgress && !extraNumber">|</span>
              <feather-icon v-if="!extraNumber" class="ml-1 w-4 cursor-pointer text-primary" icon="PlusCircleIcon" @click="extraNumber = !extraNumber"></feather-icon>
            </shipblu-input>
          </div>
          <span class="absolute text-primary right-2 px-2 flex items-center" style="top: 12px;">
            <feather-icon v-if="!extraNumber && !selectionInProgress" class="ml-1 w-4 cursor-pointer" icon="PlusCircleIcon" @click="extraNumber = !extraNumber"></feather-icon>
          </span>
          <span
            v-if="!selectionInProgress && !(searchPhoneVal.length > 0) && !(customerData.phone)"
            class="absolute pointer-events-none text-[14px] left-4 text-industrial-revolution"
            style="top: 50%; transform: translateY(-50%);"
          >{{$t('Mobile Number')}} <span class="text-fluorescent-red">*</span></span>
        </div>
        <p class="text-danger text-sm" v-show="errors.has('phone number')">{{ $t('Mobile number is required') }}</p>
      </div>
      <div>
        <shipblu-input labelBackground="white" autocomplete="nofill" id="name-input" v-validate="'required|max:20'" name="full name" :labelPlaceholder="$t('Full Name')" :required="true" v-model="customerData.full_name" type="text" class="w-full" />
        <p class="text-danger text-sm" v-show="errors.has('full name')">{{ $t('Full name is required') }}</p>
      </div>
      <template v-if="extraNumber">
        <div>
          <shipblu-input labelBackground="white" autocomplete="nofill" id="extra-mobile-number-input" name="extra mobile number" :labelPlaceholder="$t('Extra Mobile Number (Optional)')"  v-model="customerData.secondary_phone" type="text" class="w-full" 
          v-validate="{ regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" />
        </div>
      </template>
      <div class="autoZoning md:col-span-2 flex custom-switch">
        <vs-switch @change="completeAddress = !autoZoning" class="mr-3" v-model="autoZoning" />
        <span class="font-medium text-black">{{$t('Auto-zoning')}}</span>
      </div>
      <template>
        <div>
          <shipblu-input labelBackground="white" @blur="fillAddress()" @keyup.enter.native="autoZoning ? fillAddress() : ''" autocomplete="nofill" v-validate="'required|min:10'" name="full address" :labelPlaceholder="$t('Full Address')" :required="true" v-model="customerData.street_building" type="text" class="w-full" />
          <p class="text-danger text-sm" v-show="errors.has('full address')">{{ $t(errors.first('full address')) }}</p>
        </div>
        <div @click="handleBlur" v-if="showDropdown === true" class="fixed inset-0 bg-transparent"></div>
        <div v-show="!autoZoning">
          <div class="relative">
            <span class="absolute right-0 top-1/2 text-bauhaus mx-4 flex items-center" style="transform: translateY(-50%);">
              <feather-icon class="w-4 cursor-pointer z-[1]" :icon="showDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
            </span>
            <span v-if="!shipmentOldZone && !searchQuery" class="absolute pointer-events-none text-fluorescent-red text-lg" :class="$route.params.lang === 'en' ? 'left-14' : 'left-[65px]'" style="top: 50%; transform: translateY(-50%);">*</span>
            <input
              type="text"
              name="zone"
              v-validate="'required'"
              v-model="searchQuery"
              @focus="handleFocus"
              :placeholder="shipmentOldZone ? shipmentOldZone : $t('Zone')"
              class="relative h-[48px] bg-transparent p-4 rounded-md border border-solid w-full md:text-[14px] text-[12px] input-placeholder"
              autocomplete="off"
            />
            <ul
              v-show="showDropdown" 
              class="absolute w-full bg-lightgray border border-solid rounded-[5px] border-grey mt-[4px] py-[16px] px-[12px] overflow-y-auto z-[99993]"
              :style="{ maxHeight: dropdownMaxHeight }"
            >
              <li v-if="filteredZones.length === 0" class="font-medium md:text-[14px] text-[12px] text-center text-black">
                {{ $t('Sorry, no matching option') }}
              </li>
              <li v-else v-for="region in filteredZones.filter(item => item.code !== 'NOGOV')" :key="region.id" class="border-b">
                <div class="font-medium md:text-[14px] text-[12px] cursor-pointer flex items-center gap-1 text-industrial-revolution">
                  <span class="whitespace-nowrap py-[2px]">{{ $t(region.name) }}</span>
                  <div class="w-full h-[1px] bg-cold-morning"></div>
                </div>
                <ul v-for="city in region.cities" :key="city.id">
                  <li>
                    <div
                      class="font-medium text-black md:text-[14px] text-[12px] cursor-pointer flex justify-between py-[2px] my-[4px]"
                      @click="toggleCity(city)"
                    >
                      {{ $t(city.name) }}
                      <feather-icon :icon="city === expandedCity ? 'ChevronDownIcon' : $route.params.lang === 'ar' ? 'ChevronLeftIcon' : 'ChevronRightIcon'" svgClasses="w-5 h-5"/>
                    </div>
                    <ul v-show="city === expandedCity" class="px-[8px]">
                      <li
                        v-for="zone in city.zones"
                        :key="zone.id"
                        @click="selectZone(zone)"
                        class="cursor-pointer font-medium text-black md:text-[14px] text-[12px] py-[2px] my-[4px]"
                        >
                        {{ $t(zone.name) }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <p class="text-danger text-sm" v-show="errors.has('zone')">{{ $t('Zone field is required') }}</p>
        </div>
        <div v-show="autoZoning" class="relative cursor-not-allowed">
          <shipblu-input labelBackground="white" autocomplete="nofill" :labelPlaceholder="$t('Zone')" :required="true" v-model="searchQuery" type="text" class="w-full pointer-events-none" :tabindex="-1" />
          <div v-if="loadAddress" class="absolute top-4 left-4 flex items-center gap-[8px] bg-white z-[1]">
            <div class="border-r-white border border-solid border-primary w-[14px] h-[14px] rounded-full flex-shrink-0 animate-spin"></div>
            <p class="text-industrial-revolution">{{ $t('Auto zoning is working..') }}</p>
          </div>
        </div>
      </template>
      <div v-if="activeTab === 'cash-collections'">
        <shipblu-input labelBackground="white" autocomplete="nofill" v-validate="'required'" name="cash amount" :labelPlaceholder="$t('Cash Amount')" :required="true" v-model="customerData.cash_collections" type="text" class="w-full" @blur="validateCashCollections"/>
        <p class="text-danger text-sm" v-show="errors.has('cash amount')">{{ $t('Cash Amount is required') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common from '../../../assets/utils/common'
import { sendRequest }  from '../../../http/axios/requestHelper'
import staticZones from '../../../assets/utils/geo.json'
import ShipbluInput from '@/layouts/components/ShipbluInput.vue'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['activeTab', 'changebleTabValue', 'restData', 'customerData'],
  data () {
    return {
      selectionInProgress: false,
      searchInProgress: false,
      autoZoning: false,
      extraNumber: false,
      zones: staticZones,
      shipment: {
        phone:'',
        zone: null
      },
      searchQuery: '',
      showDropdown: false,
      expandedCity: null,
      phones:[],
      searchPhoneVal: '',
      searchedPhoneValue: ' ',
      shipmentOldZone: '',
      completeAddress: false,
      dropdownMaxHeight: 0,
      loadAddress: false
    }
  },
  components: {
    vSelect,
    ShipbluInput
  },
  watch: {
    autoZoning () {
      this.searchQuery = ''
    },
    showDropdown (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.calculateDropdownHeight()
        })
      }
    },
    restData (val) {
      if (val) {
        this.resetForm()
        this.$emit('restData', false)
      }
    },
    'customerData.phone' (selected) {
      if (selected !== null && typeof (selected) === 'object') {
        const zoneId = selected.customer.address.zone.id
        this.updateSearchQuery(zoneId)
        this.customerData.full_name = selected ? selected.customer.full_name : ''
        this.searchPhoneVal = selected ? selected.customer.phone : ''
        this.customerData.secondary_phone = selected && selected.customer.secondary_phone !== null ? selected.customer.secondary_phone : null
        this.customerData.street_building = selected && selected.customer.address.line_1 !== null ? `${selected.customer.address.line_1} ${selected.customer.address.line_2}` : ''
        this.selectionInProgress = true
      }
      this.$emit('customerData', this.customerData)
    },
    'searchPhoneVal' (val) {
      if (typeof (val) === 'object') {
        this.customerData.full_name = val.customer.full_name
        this.customerData.phone = val.customer.phone
        this.$emit('customerData', this.customerData)
      }
      this.$emit('searchPhoneVal', val)
    },
    selectionInProgress () {
      this.customerData.phone = this.selectionInProgress ? this.searchPhoneVal : this.customerData.phone
      this.$emit('customerData', this.customerData)
    }
  },
  computed: {
    filteredZones () {
      if (!this.searchQuery) return this.zones
      const query = this.searchQuery.toLowerCase()
      return this.zones
        .map((region) => {
          const matchingCities = (region.cities || [])
            .map((city) => {
              const matchingZones = (city.zones || []).filter((zone) => zone.name.toLowerCase().includes(query)
              )
              return matchingZones.length || city.name.toLowerCase().includes(query) ? { ...city, zones: matchingZones } : null
            })
            .filter((city) => city)
          return matchingCities.length || region.name.toLowerCase().includes(query) ? { ...region, cities: matchingCities } : null
        })
        .filter((region) => region)
    }
  },
  methods: {
    resetForm () {
      this.searchQuery = ''
      this.shipmentOldZone = ''
      this.customerData = {
        phone: '',
        full_name: '',
        secondary_phone: '',
        street_building: '',
        zone: '',
        governorate: ''
      }
      this.selectionInProgress = false
      this.$emit('customerData', this.customerData)
      if (this.changebleTabValue.newTab) {
        this.$router.push({
          query: {
            tab: this.changebleTabValue.newTab
          }
        }).catch(() => {})
      }
      this.convertTextToSelect()
      this.$emit('activeTab', this.activeTab)
      this.$validator.reset()
    },
    fillAddress () {
      if (this.customerData.street_building.length > 10) {
        this.loadAddress = true
        this.searchQuery = ''
        const orderAddress = {
          full_address: this.customerData.street_building ? this.customerData.street_building : ''
        }
        sendRequest(true, false, this, 'api/v2/gpt/autozoner/', 'post', orderAddress, true, 
          (response) => {
            this.loadAddress = false
            this.completeAddress = true
            this.customerData = {
              ...this.customerData,
              street_building: response.data.line_1,
              governorate: response.data.zone.city.governorate,
              zone: response.data.zone
            }
            this.updateSearchQuery(this.customerData.zone.id)
            this.$emit('customerData', this.customerData)
          }
        )
      }
    },
    handleFocus () {
      this.showDropdown = true
      this.shipmentOldZone = this.searchQuery
      this.searchQuery = ''
      this.$validator.reset('zone')
      this.$nextTick(() => {
        const container = document.getElementById('scrollContainer')
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth'
          })
        }
      })
    },
    handleBlur () {
      if (!this.selectedZone) {
        this.searchQuery = ''
      }
      if (this.shipmentOldZone && this.shipmentOldZone.length > 0) {
        this.searchQuery = this.shipmentOldZone 
      }
      this.showDropdown = false
    },
    toggleCity (city) {
      this.expandedCity = this.expandedCity === city ? null : city
    },
    selectZone (zone) {
      const city = this.expandedCity
      const region = this.zones.find(region => region.cities.some(cityItem => cityItem.id === city.id))
      this.customerData.zone = zone
      this.customerData.governorate = region
      this.searchQuery = `${i18nData[this.$i18n.locale][region.name]} - ${i18nData[this.$i18n.locale][city.name]} - ${i18nData[this.$i18n.locale][zone.name]}`
      this.showDropdown = false
      this.$emit('customerData', this.customerData)
    },
    handleChangePhone (val) {
      if (val && typeof (val) === 'object') {
        this.searchPhoneVal = val.customer.phone
        this.selectionInProgress = true
      }
    },
    handlePhoneNumberSearch (search) {
      this.searchPhoneVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPhoneNumberSearch, 1000, 5)
    },
    loadPhoneNumberSearch () {
      if (this.searchInProgress || this.searchedPhoneValue === this.searchPhoneVal) {
        return
      }
      if (this.searchPhoneVal) {
        this.loadPhoneNumber()
      }
    },
    loadPhoneNumber () {
      this.searchInProgress = true
      sendRequest(true, false, this, `api/v1/delivery-orders/?customer_phone=${this.searchPhoneVal}`, 'get', null, true,
        (response) => {
          this.phones = response.data.results
          this.phones.forEach(item => {
            item.label = `${item.customer.phone} ${item.customer.full_name}`
          })
          this.searchedPhoneValue = this.searchPhoneVal
          if (response.data.count === 0) {
            this.customerData.phone = this.searchedPhoneValue
          }
          this.selectionInProgress = this.phones.length === 0
          if (this.phones.length === 0) {
            this.$nextTick(() => {
              const input = document.getElementById('phone-input')
              input.setAttribute('value', this.searchPhoneVal)
              if (this.$refs.phoneInput) {
                this.$refs.phoneInput.$el.querySelector('input').focus()
              }
            })
          }
          this.$emit('customerData', this.customerData)
          this.$validator.validate('phone number')
        },
      )
      this.searchInProgress = false
    },
    convertTextToSelect () {
      this.customerData = {
        phone: '',
        full_name: '',
        secondary_phone: '',
        street_building: '',
        zone: '',
        governorate: ''
      }
      this.searchPhoneVal = ''
      this.$emit('customerData', this.customerData)
      this.selectionInProgress = false
      this.searchQuery = ''
      this.shipmentOldZone = ''
      this.$validator.reset()
    },
    validateCashCollections () {
      const value = parseFloat(this.customerData.cash_collections)
      if (isNaN(value)) {
        this.customerData.cash_collections = (0).toFixed(2)
        
      } else {
        this.customerData.cash_collections = value.toFixed(2)
      }
      this.$emit('customerData', this.customerData)
    },
    updateSearchQuery (zoneId) {
      if (!Array.isArray(this.zones)) {
        return
      }
      const matchingRegion = this.zones.find((region) => Array.isArray(region.cities) && region.cities.some((city) => Array.isArray(city.zones) && city.zones.some((zone) => zone.id === zoneId)))
      if (matchingRegion) {
        const matchingCity = matchingRegion.cities.find((city) => Array.isArray(city.zones) && city.zones.some((zone) => zone.id === zoneId))
        if (matchingCity) {
          const matchingZone = matchingCity.zones.find((zone) => zone.id === zoneId)
          if (matchingZone) {
            this.customerData.zone = matchingZone
            this.customerData.governorate = matchingRegion
            this.searchQuery = `${i18nData[this.$i18n.locale][matchingRegion.name]} - ${i18nData[this.$i18n.locale][matchingCity.name]} - ${i18nData[this.$i18n.locale][matchingZone.name]}`
            this.$emit('customerData', this.customerData)
            return
          }
        }
      }
      this.searchQuery = ''
    },
    calculateDropdownHeight () {
      const scrollContainer = document.getElementById('scrollContainer')
      const zoneInput = document.querySelector('input[name="zone"]')
    
      if (scrollContainer && zoneInput) {
        const inputRect = zoneInput.getBoundingClientRect()
        const containerRect = scrollContainer.getBoundingClientRect()
        const scrollBottom = scrollContainer.scrollHeight
        const inputTopRelativeToContainer = inputRect.top - containerRect.top + scrollContainer.scrollTop
        const availableHeight = scrollBottom - inputTopRelativeToContainer
        const padding = 16
        const dropdownTopMargin = 4
        const maxHeight = availableHeight - padding - dropdownTopMargin - inputRect.height
        this.dropdownMaxHeight = `${Math.max(maxHeight, 100)}px`
      }
    }
  },
  mounted () {
    this.$emit('customerData', this.customerData)
    window.addEventListener('resize', this.calculateDropdownHeight)
    const scrollContainer = document.getElementById('scrollContainer')
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', this.calculateDropdownHeight)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculateDropdownHeight)
    const scrollContainer = document.getElementById('scrollContainer')
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.calculateDropdownHeight)
    }
  }
} 
</script>

<style lang="scss">
input::placeholder {
  size: 25px;
}
.v-select:has(input:focus){
  outline: 1px solid #1C5BFE;
  border: 1px solid #1C5BFE !important;
  border-radius: 4px !important;
}
.vs__selected-options {
  overflow: hidden;
  white-space: nowrap;
}
.v-select {
  &.select-border {
    ul {
      min-width: fit-content;
    }
    ::placeholder {
      color: #BFBFBF !important;
      font-size: 14px !important;
    }
    .vs__dropdown-toggle {
      border: none;
    }
    .vs__actions {
      display: none;
    }
  }
  .feather-chevron-down, .feather-x {
    stroke: #6C84A3;
  }
  ul {
    border: none;
    overflow-x: hidden;
    width: 100%;
  }
}
.bg-lightgray {
  background-color: #f8f8f8;
}
.autoZoning .vs-switch--circle{
  background: #D1D1D1 !important;
  margin-top: -1px;
}
.custom-switch .vs-switch {
  border: 1px solid #BFBFBF;
  background-color: transparent;
}
.vs-switch-primary.vs-switch-active{
  background-color: rgba(var(--vs-primary),1);
  border: 1px solid rgba(var(--vs-primary),1);
}
.vs-switch-active .vs-switch--circle{
  background: #fff !important;
}
.vs__search, .vs__search:focus{
  padding: 0px 12px;
}
.dropdown-selector-class {
  max-height: 130px;
}
.input-placeholder {
  border: 1px solid #BFBFBF;
  &::placeholder {
    font-size: 14px;
    color: #737373 !important;
  }
}
.input-placeholder:focus {
  outline: 1px solid #1C5BFE;
  border: 1px solid #1C5BFE !important;
}
.vs__dropdown-menu {
  margin-top: 8px;
  border: 1px solid #b8c2cc !important;
  box-shadow: none;
  background-color: #f8f8f8;
}
#vs1__combobox {
  padding: 0 5px;
}
.vs__selected{
  padding: 1px 14px;
  color: #232323 !important;
}
.vs--single.vs--open .vs__selected, .vs--single.vs--loading .vs__selected {
  padding: 2px 13px;
  opacity: 1 !important;
}
</style>